export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const FILL_EMPLOYEE_DATA = 'FILL_EMPLOYEE_DATA';

export const fillEmployees = (employees = []) => ({
	type: GET_EMPLOYEES,
	payload: {
		employees
	},
});

export const fillEmployeeData = (employee = {}) => ({
	type: FILL_EMPLOYEE_DATA,
	payload: {
		employee
	},
});
