export const GET_NI_CATEGORIES = 'GET_NI_CATEGORIES';
export const GET_NI_CATEGORY_DATA = 'GET_NI_CATEGORY_DATA';

export const fillNICategories = (ni_categories = []) => ({
	type: GET_NI_CATEGORIES,
	payload: {
		ni_categories
	},
});


export const getNICategoriesData = (ni_category = {}) => ({
	type: GET_NI_CATEGORY_DATA,
	payload: {
		ni_category
	},
});