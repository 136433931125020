export const GET_USERS = 'GET_USERS';
export const GET_USER_DATA = 'GET_USER_DATA';

export const fillUsers = (users = []) => ({
	type: GET_USERS,
	payload: {
		users
	},
});


export const getUserData = (user = {}) => ({
	type: GET_USER_DATA,
	payload: {
		user
	},
});