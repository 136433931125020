import {GET_CARDS} from "./actions/dashboardActions";

const initialState = {
	cards: [],
};

const dashboardReducer = (state = initialState, {payload, type}) => {
	switch (type) {
		case GET_CARDS:
			return {
				...state,
				...payload,
			};
		default:
			return state;
	}
};

export default dashboardReducer;
