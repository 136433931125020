import {GET_NI_CATEGORIES, GET_NI_CATEGORY_DATA} from "./actions/NICategories";

const initialState = {
	ni_categories: [],
	ni_category: {
		category: "",
		description: "",
		priority: 1,
	},
}

const employersReducer = (state = initialState, {payload, type}) => {
	switch (type) {
		case GET_NI_CATEGORY_DATA:
		case GET_NI_CATEGORIES:
			return {
				...state,
				...payload,
			};
		default:
			return state;
	}
};

export default employersReducer;
