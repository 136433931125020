// src/redux/reducers/toastReducer.js
import {HIDE_TOAST, SHOW_TOAST} from './actions/toastActions';

const initialState = {
	visible: false,
	messages: [],
	color: 'primary'
};

const toastReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_TOAST:
			return {
				...state,
				visible: true,
				messages: action.payload.messages,
				color: action.payload.color
			};
		case HIDE_TOAST:
			return {
				...state,
				visible: false,
				messages: [],
				color: 'primary'
			};
		default:
			return state;
	}
};

export default toastReducer;
