// src/redux/actions/toastActions.js
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const showToast = (messages, color = 'primary', visible = true) => ({
	type: SHOW_TOAST,
	payload: {messages, color, visible},
});

export const hideToast = () => ({
	type: HIDE_TOAST
});
