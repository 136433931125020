// src/utils/axiosInstance.js
import axios from 'axios';
import {hideToast, showToast} from '../redux/actions/toastActions';

const createAxiosInstance = (dispatch) => {
	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_API,
		timeout: 10000,
		headers: {'Content-Type': 'application/json'},
	});

	// Interceptor de requisição
	axiosInstance.interceptors.request.use(
		config => {
			const token = localStorage.getItem('fpAdminToken');
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			dispatch(hideToast());
			return config;
		},
		error => {
			return Promise.reject(error);
		}
	);

	// Interceptor de resposta
	axiosInstance.interceptors.response.use(
		response => response,
		error => {
			if (error.response && error.response.status === 401) {
				window.location.href = '/login';
			}

			if (error.response && error.response.status === 422) {
				dispatch(showToast(error.response.data.errors, 'danger', true));
			} else if (error.response) {
				dispatch(showToast([`Erro: ${error.response.status}`], 'danger', true));
			} else {
				dispatch(showToast([
					'Unknow error, please contact support team'
				], 'danger', true));
			}

			console.error(error);

			// return Promise.reject(error);
		}
	);

	return axiosInstance;
};

export default createAxiosInstance;
