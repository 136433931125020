import themeReducer from "./themeReducer";
import employersReducer from "./employersReducer";
import usersReducer from "./usersReducer";
import toastReducer from "./toastReducer";
import dashboardReducer from "./dashboardReducer";
import employeesReducer from "./employeesReducer";
import NICategoriesReducer from "./NICategoriesReducer";
import {combineReducers} from "redux";

const reducers = combineReducers({
	themeReducer,
	toastReducer,
	employersReducer,
	usersReducer,
	dashboardReducer,
	employeesReducer,
	NICategoriesReducer,
});

export default reducers;