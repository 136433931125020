import {FILL_EMPLOYEE_DATA, GET_EMPLOYEES} from "./actions/employeesActions";

const initialState = {
	employees: [],
	employee: {
		new_pay_schedule_id: null,
		forename: "",
		safe_id: "",
		surname: "",
		email: "",
		telephone: "",
		status: "",
		gender: "",
		birthdate: "",
		ni_category: "",
		national_insurance_number: "",
		tax_code: "",
		is_director: false,
		address: {
			address_line_1: "",
			address_line_2: "",
			country: "",
		},
		active_director_detail: {
			locked: false,
			cumulative_calculation: false,
			end_date: "",
			start_date: "",
		},
		director_details: {
			locked: false,
			cumulative_calculation: false,
			start_date: "",
		},
		pay_schedule: {
			created_at: "",
			custom_payday: "",
			day_rate_method: "",
			employees_count: null,
			employer_id: null,
			frequency_code: "",
			frequency_code_id: null,
			id: null,
			laravel_through_key: null,
			month_payday: null,
			name: "",
			starting_period_end_date: "",
			updated_at: "",
			week_payday: null,
			active_pay_run: {
				active: true,
				can_be_rolled_back: false,
				created_at: "",
				employer_pay_schedule_id: null,
				final_tax_year_submission: false,
				fps_submitted: false,
				id: null,
				last_completed_at: null,
				pay_run_period: "",
				period_end_date: "",
				period_start_date: "",
				reassess_employees: false,
				resubmission_needed: false,
				rollback_end_period: null,
				rollback_in_progress: false,
				rollback_started_period: null,
				status: "",
				tax_period: null,
				tax_year_id: null,
				updated_at: "",
			}
		},
		salary: {
			id: null,
			employment_type: "",
			effective_date: null,
			employee_id: null,
			expected_work_hours_per_week: 0.0,
			annual_salary: 0.0,
			hourly_salary: 0.0,
			monthly_salary: 0.0,
			weekly_salary: 0.0,
			created_at: "",
			updated_at: "",
		},
		employer: {
			account_locked: false,
			agriculture_sector: false,
			apprentice_levy_due: false,
			aquaculture_sector: false,
			basic_setup_complete: true,
			basic_setup_completed_at: "",
			basic_setup_step: 3,
			company_name: "",
			company_number: "",
			created_at: "",
			creation_method: "",
			disable_lead_emails: false,
			employment_allowance: true,
			eps_automatic: true,
			holiday_year_start_month: "",
			id: null,
			is_manual_creation: true,
			last_reenrolment_date: "",
			legal_structure: "",
			onboarding_complete: true,
			other_sector: true,
			pay_schedules: [],
		}
	},
};

const employeesReducer = (state = initialState, {payload, type}) => {
	switch (type) {
		case GET_EMPLOYEES:
		case FILL_EMPLOYEE_DATA:
			return {
				...state, ...payload,
			};
		default:
			return state;
	}
};

export default employeesReducer;
