export const GET_EMPLOYERS = 'GET_EMPLOYERS';
export const GET_EMPLOYER_DATA = 'GET_EMPLOYER_DATA';

export const fillEmployers = (employers = []) => ({
	type: GET_EMPLOYERS,
	payload: {employers},
});


export const getEmployerData = (employer = {}) => ({
	type: GET_EMPLOYERS,
	payload: {employer},
});