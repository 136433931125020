import {GET_USER_DATA, GET_USERS,} from "./actions/usersActions";

const initialState = {
	users: [],
	user: {
		profile_type: {
			name: "",
		},
	},
}

const employersReducer = (state = initialState, {payload, type}) => {
	switch (type) {
		case GET_USERS:
		case GET_USER_DATA:
			return {
				...state,
				...payload,
			};
		default:
			return state;
	}
};

export default employersReducer;
