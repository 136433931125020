import {applyMiddleware, legacy_createStore as createStore,} from 'redux';
import {thunk} from 'redux-thunk';
import createAxiosInstance from './utils/axiosInstance';
import reducers from "./redux/reducers";


const store = createStore(reducers, applyMiddleware(thunk));
const axiosInstance = createAxiosInstance(store.dispatch);

export {store, axiosInstance};
