import {GET_EMPLOYER_DATA, GET_EMPLOYERS} from "./actions/employersActions";

const initialState = {
	employers: [],
	employer: {
		account_locked: false,
		agriculture_sector: false,
		apprentice_levy_due: false,
		aquaculture_sector: false,
		basic_setup_complete: true,
		basic_setup_completed_at: "",
		basic_setup_step: 3,
		company_name: "",
		company_number: "",
		created_at: "",
		creation_method: "",
		disable_lead_emails: false,
		employment_allowance: false,
		eps_automatic: true,
		holiday_year_start_month: "January",
		id: 1,
		is_manual_creation: false,
		last_reenrolment_date: "",
		legal_structure: "",
		onboarding_complete: true,
		other_sector: false,
		reenrolment_date: "",
		road_transport_sector: false,
		show_close_year: false,
		small_employer_relief: false,
		staging_date: "",
		tax_year_id: 1,
		updated_at: "",
		address: {
			address_line_1: "",
			address_line_2: "",
			address_snippet: "",
			bureau_id: null,
			city: "",
			country: "",
			created_at: "",
			employee_id: null,
			employer_id: 1,
			id: 1,
			is_registered_address: false,
			postal_code: "",
			updated_at: "",
		},
		notes: [],
	},
}

const employersReducer = (state = initialState, {payload, type}) => {
	switch (type) {
		case GET_EMPLOYERS:
		case GET_EMPLOYER_DATA:
			return {...state, ...payload};
		default:
			return state;
	}
};

export default employersReducer;
