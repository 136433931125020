const initialState = {
	sidebarShow: true,
	theme: 'dark',
	sidebarUnfoldable: null,
}

const changeState = (state = initialState, {type, ...rest}) => {
	switch (type) {
		case 'set':
			return {
				...state,
				...rest
			};
		default:
			return state;
	}
};

export default changeState;